const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
};

function chunkSubstr(str, size) {
  const numChunks = Math.ceil(str.length / size);
  const chunks = new Array(numChunks);

  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str.substr(o, size);
  }

  return chunks;
}

const isEmptyOrNull = (value) => {
  return value == "" || value == undefined || value == null;
};

const validateAddress = (address, type) => {
  console.log("validateAddress called", address, type);
  let result = [true, "Good"];
  let { name, attention, email, phone, addressLine1, addressLine2, addressLine3, state, country, zip, city } = address;
  if (isEmptyOrNull(name)) {
    result = [false, "Name can not be empty"];
  }
  if (isEmptyOrNull(attention)) {
    result = [false, "Attention can not be empty"];
  }
  if (isEmptyOrNull(email)) {
    result = [false, "Email can not be empty"];
  }
  if (!validateEmail(email)) {
    result = [false, "Email is not valid"];
  }
  if (isEmptyOrNull(phone)) {
    result = [false, "Phone can not be empty"];
  }
  if (isEmptyOrNull(addressLine1)) {
    result = [false, "Address Line 1 can not be empty"];
  }
  if (isEmptyOrNull(addressLine2)) {
    result = [false, "Address Line 2 can not be empty"];
  }
  if (isEmptyOrNull(city)) {
    result = [false, "City can not be empty"];
  }
  if (isEmptyOrNull(state)) {
    result = [false, "State can not be empty"];
  }
  if (isEmptyOrNull(country)) {
    result = [false, "Country can not be empty"];
  }
  if (isEmptyOrNull(zip)) {
    result = [false, "Zip can not be empty"];
  }
  return result;
};

module.exports = { validateEmail, chunkSubstr, validateAddress };
