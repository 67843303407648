import { Button, Checkbox, Col, Card, Form, Input, Row, Collapse, Select, notification, Modal, Table, Space, theme, Steps, Tabs, List, Descriptions } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined, SettingOutlined, ArrowLeftOutlined, UserOutlined, VideoCameraOutlined, ReloadOutlined } from "@ant-design/icons";
import { Fragment, useEffect, useState } from "react";
import * as animationData from "../serviceanim.json";
import Lottie from "react-lottie";
import { validateEmail } from "../utils/methods";
import axios from "axios";
import { API_ENDPOINT } from "../api/endPoint";
import { useNavigate } from "react-router-dom";
import CreateServiceRequest from "./CreateServiceRequest";
import Address from "./Address";

const SRList = () => {
  const { useToken } = theme;
  const { token } = useToken();
  const [api, contextHolder] = notification.useNotification();
  const [isCreating, setIsCreating] = useState(false);
  const navigate = useNavigate();
  const [srs, setSrs] = useState([]);
  const [bsrs, setBackupSRS] = useState([]);
  const [isGettingStatus, setIsGettingStatus] = useState(false);
  const [isGettingStatusIndi, setIsGettingStatusIndi] = useState(false);
  const [searchText, setSearchText] = useState("");
  const { Panel } = Collapse;
  const { Search } = Input;

  const columns = [
    {
      title: "eID",
      dataIndex: "eid",
      key: "eid",
    },
    {
      title: "Problem",
      dataIndex: "problem",
      key: "problem",
    },
    {
      title: "Description",
      dataIndex: "desc",
      key: "desc",
    },
  ];

  const getStatus = async (srn, item) => {
    try {
      setIsGettingStatus(true);
      let response = await axios.get(API_ENDPOINT + "/status/" + srn);
      if (response.data.success && response.data.status && response.data.status.statuses && response.data.status.statuses.length > 0) {
        item["status"] = response.data.status;
      }
      setIsGettingStatus(false);
    } catch (err) {
      setIsGettingStatus(false);
    }
  };

  const getServiceRequests = async () => {
    try {
      let response = await axios.get(API_ENDPOINT + "/list/by");
      console.log(response.data);
      if (response.data.list) {
        response.data.list.forEach((item) => {
          item["key"] = item["_id"];
          if (item.orderId && item.dcId) {
            item["statusLean"] = "DC & ORDER";
          } else if (item.orderId) {
            item["statusLean"] = "ORDER";
          } else if (item.dcId) {
            item["statusLean"] = "DC";
          } else if (item.isAdvanceReplaced) {
            item["statusLean"] = "ADVANCE-REPLACED";
          } else if (item.isDeviceReceived) {
            item["statusLean"] = "DEVICE-RECEIVED";
          } else {
            item["statusLean"] = "Awaiting Device Arrival";
          }
        });
        setSrs(response.data.list);
        setBackupSRS(response.data.list);
      }
    } catch (err) {}
  };

  const getServiceRequestsSingle = async () => {
    try {
      if (searchText == "") {
        await getServiceRequests();
        return;
      }
      let response = await axios.get(API_ENDPOINT + "/list/by/single/" + searchText);
      console.log(response.data);
      if (response.data.list) {
        response.data.list.forEach((item) => {
          item["key"] = item["_id"];
          if (item.orderId && item.dcId) {
            item["statusLean"] = "DC & ORDER";
          } else if (item.orderId) {
            item["statusLean"] = "ORDER";
          } else if (item.dcId) {
            item["statusLean"] = "DC";
          } else if (item.isAdvanceReplaced) {
            item["statusLean"] = "ADVANCE-REPLACED";
          } else if (item.isDeviceReceived) {
            item["statusLean"] = "DEVICE-RECEIVED";
          } else {
            item["statusLean"] = "Awaiting Device Arrival";
          }
        });
        setSrs(response.data.list);
        setBackupSRS(response.data.list);
      }
    } catch (err) {}
  };

  useEffect(() => {
    getServiceRequests();
  }, []);

  const handleChange = (value) => {
    let newSrs = [];
    if (value == "all") {
      newSrs = bsrs;
    } else if (value == "dc") {
      newSrs = bsrs.filter((sr) => {
        return sr.dcId != undefined;
      });
    } else if (value == "order") {
      newSrs = bsrs.filter((sr) => {
        return sr.orderId != undefined;
      });
    } else if (value == "both") {
      newSrs = bsrs.filter((sr) => {
        return sr.dcId != undefined && sr.orderId != undefined;
      });
    } else if (value == "ar") {
      newSrs = bsrs.filter((sr) => {
        return sr.isAdvanceReplaced;
      });
    } else if (value == "ada") {
      newSrs = bsrs.filter((sr) => {
        return sr.isDeviceReceived == undefined || !sr.isDeviceReceived;
      });
    }

    setSrs(newSrs);
  };

  return (
    <Fragment>
      <Col span={24}>
        <Search
          allowClear
          onChange={(event) => {
            setSearchText(event.target.value);
            if (event.target.value == "") {
              getServiceRequests();
            }
          }}
          placeholder="Type SRN / eID / Billing Address Name/Customer Information & Press Enter"
          loading={isGettingStatusIndi}
          enterButton
          style={{ marginBottom: 20 }}
          onPressEnter={async () => {
            await getServiceRequestsSingle();
          }}
        />
        <Row style={{ display: "flex", justifyContent: "flex-end", marginBottom: 20, verticalAlign: "center" }}>
          <strong style={{ marginRight: 20, marginTop: 5 }}>Filter :</strong>
          <Select
            defaultValue="all"
            style={{
              width: 180,
              verticalAlign: "center",
            }}
            onChange={handleChange}
            options={[
              {
                value: "all",
                label: "All",
              },
              {
                value: "dc",
                label: "Delivery Challan",
              },
              {
                value: "order",
                label: "Service Order",
              },
              {
                value: "both",
                label: "Service Order & DC",
              },
              {
                value: "ar",
                label: "Advance Replaced",
              },
              {
                value: "ada",
                label: "Awaiting Device Arrival",
              },
            ]}
          />
        </Row>
        <List
          header={<div>All Service Requests :</div>}
          bordered
          dataSource={srs}
          renderItem={(item) => (
            <List.Item>
              <Collapse style={{ width: "100%" }}>
                <Panel header={`SRN : ${item.srn}`} extra={`Status : ${item.statusLean}`}>
                  <Col span={24}>
                    <Collapse>
                      <Panel header="Devices" key="1">
                        <Table dataSource={item.devices} columns={columns} style={{ overflow: "scroll" }} />
                      </Panel>
                    </Collapse>
                    {item.custInfo != undefined && (
                      <Collapse style={{ marginTop: 10 }}>
                        <Panel header="Customer Information" key="1">
                          <Descriptions title="User Info">
                            {item.custInfo.name && <Descriptions.Item label="Name">{item.custInfo.name}</Descriptions.Item>}
                            {item.custInfo.email && <Descriptions.Item label="Email">{item.custInfo.email}</Descriptions.Item>}
                            {item.custInfo.phone && <Descriptions.Item label="Mobile">{item.custInfo.phone}</Descriptions.Item>}
                            {item.custInfo.comment && <Descriptions.Item label="Additional Information">{item.custInfo.comment}</Descriptions.Item>}
                          </Descriptions>
                        </Panel>
                      </Collapse>
                    )}
                    <Row gutter={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
                      <Col span={{ sm: 24, md: 12, xs: 24 }} style={{ padding: 10 }} flex={1}>
                        <Collapse>
                          <Panel header="Billing Address" key="1">
                            <Address type="billing" address={item.bAddress} onAddressChange={() => {}} />
                          </Panel>
                        </Collapse>
                      </Col>
                      <Col span={{ sm: 24, md: 12 }} style={{ padding: 10 }} flex={1}>
                        <Collapse>
                          <Panel header="Shipping Address" key="2">
                            <Address type="shipping" address={item.sAddress} onAddressChange={() => {}} />
                          </Panel>
                        </Collapse>
                      </Col>
                    </Row>
                    <Collapse>
                      <Panel
                        header="Status"
                        extra={
                          <Button
                            type="primary"
                            icon={<ReloadOutlined />}
                            loading={isGettingStatus}
                            onClick={() => {
                              getStatus(item.srn, item);
                            }}
                          />
                        }
                      >
                        {item.status && item.status.statuses && item.status.statuses.length > 0 && (
                          <Card title={`Status for Service Request : ${item.srn.toUpperCase()}`} style={{ marginTop: 20, backgroundColor: "transparent" }}>
                            <Steps items={item.status.statuses} status="finish" current={item.status.current} direction="vertical" />
                          </Card>
                        )}
                      </Panel>
                    </Collapse>
                  </Col>
                </Panel>
              </Collapse>
            </List.Item>
          )}
        />
      </Col>
    </Fragment>
  );
};

export default SRList;
