import "./App.css";
import { MenuFoldOutlined, MenuUnfoldOutlined, HomeOutlined, UserOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { Layout, Menu, theme, Button, Alert, Space, Row, Col } from "antd";
import Search from "./components/Search";
import React, { Fragment, useState } from "react";
import CreateServiceRequest from "./components/CreateServiceRequest";
import EgluLogo from "./components/Logo";
import { Link, useNavigate } from "react-router-dom";
import { API_ENDPOINT, BASE_URL } from "./api/endPoint";
import Lottie from "react-lottie";
import * as animationData from "./serviceanim.json";
import * as animationWAllData from "./wa_all.json";
import * as animationWIndiData from "./wa_indi.json";
import { useSizeComponents } from "./utils/hooks";

const { Header, Content, Footer, Sider } = Layout;

function App() {
  const { useToken } = theme;
  const [collapsed, setCollapsed] = useState(true);
  const navigate = useNavigate();
  const { token } = useToken();

  const goToSR = () => {
    navigate("/dashboard");
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const defaultOptionsWAll = {
    loop: true,
    autoplay: true,
    animationData: animationWAllData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid meet",
    },
  };

  const defaultOptionsWIndi = {
    loop: true,
    autoplay: true,
    animationData: animationWIndiData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid meet",
    },
  };

  const [width, height] = useSizeComponents();
  const scaleLottie = 0.35;
  const xMidYMid = 0.5;
  const sizeComponent = {
    width: width * scaleLottie,
    height: width * scaleLottie * xMidYMid,
  };

  return (
    <Fragment>
      <Col span={24}>
        <div style={{ textAlign: "end", marginRight: "15px" }}>
          <a
            onClick={() => {
              navigate(localStorage.getItem("token") == undefined ? `/login` : `/dashboard`);
            }}
            style={{ color: "#ae9572", fontWeight: "bold" }}
          >
            {localStorage.getItem("token") == undefined ? `Login` : `Dashboard`}
          </a>
        </div>
      </Col>
      <div style={{ textAlign: "center" }}>
        <Lottie options={defaultOptions} height={100} width={100} />
      </div>
      <div style={{ textAlign: "center", fontSize: 25, marginBottom: 20 }}>
        <b style={{ color: token.colorText }}>Welcome to eGlu Service</b>
      </div>
      <div className="glass" style={{ padding: 20, borderRadius: 20 }}>
        <Search />
      </div>
      {/* <Col className="glass" style={{ padding: 20, borderRadius: 20, marginTop: 60, marginLeft: 5, marginRight: 5 }}>
        <Col span={24}>
          <div style={{ textAlign: "center", fontSize: 16 }}>
            <b style={{ color: token.colorText }}>New Service Request? Tap on the button below.</b>
          </div>
          <Button type="primary" style={{ width: "100%", marginTop: 20, backgroundColor: "#ae9572", color: "black" }} onClick={goToSR}>
            Create New
          </Button>
        </Col>
        <Col span={24} style={{ marginTop: 20 }}>
          <div style={{ textAlign: "center", fontSize: 16 }}>
            <b style={{ color: token.colorText }}>Need help in creating your first Service Request ?</b>
          </div>
          <Button
            type="primary"
            style={{ width: "100%", marginTop: 20, backgroundColor: "#ae9572", color: "black" }}
            onClick={() => {
              window.open(BASE_URL + "/files/tutorial.pdf", "_blank");
            }}
          >
            Learn More
          </Button>
        </Col>
      </Col> */}
      {/* <div className="glass" style={{ padding: 20, borderRadius: 20, marginTop: 60 }}>
        <h3 style={{ color: token.colorText }}>Check Real Time Status on WhatsApp :</h3>
        <Row
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32,
          }}
        >
          <Col span={12}>
            <div style={{ textAlign: "center", fontSize: 16 }}>
              <Lottie options={defaultOptionsWAll} {...sizeComponent} />
            </div>
            <Button
              type="primary"
              style={{ width: "100%", marginTop: 20, backgroundColor: "#ae9572", color: "black" }}
              onClick={() => {
                window.open("https://api.whatsapp.com/send?phone=+918867531314&text=Service+Request+Status", "_blank");
              }}
            >
              Check All
            </Button>
          </Col>
          <Col span={12}>
            <div style={{ textAlign: "center", fontSize: 16 }}>
              <Lottie options={defaultOptionsWIndi} {...sizeComponent} />
            </div>
            <Button
              type="primary"
              style={{ width: "100%", marginTop: 20, backgroundColor: "#ae9572", color: "black" }}
              onClick={() => {
                window.open("https://api.whatsapp.com/send?phone=+918867531314&text=SRS:", "_blank");
              }}
            >
              Check Individual
            </Button>
          </Col>
        </Row>
      </div> */}
    </Fragment>
  );
}

export default App;
