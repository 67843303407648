import { Button, Checkbox, Col, Card, Form, Input, Row, Collapse, Select, notification, Modal, Table, Space, theme, Tabs } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined, SettingOutlined, ArrowLeftOutlined, UserOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { Fragment, useEffect, useState } from "react";
import * as animationData from "../serviceanim.json";
import Lottie from "react-lottie";
import { validateEmail } from "../utils/methods";
import axios from "axios";
import { API_ENDPOINT } from "../api/endPoint";
import { useNavigate } from "react-router-dom";
import CreateServiceRequest from "./CreateServiceRequest";
import SRList from "./srlist";
import UserAddresses from "./UserAddresses";
import Profile from "./Profile";

const Dashboard = () => {
  const { useToken } = theme;
  const { token } = useToken();
  const [api, contextHolder] = notification.useNotification();
  const [isCreating, setIsCreating] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
  }, []);

  const onChange = () => {};

  const items = [
    {
      key: "1",
      label: `Previous Service Requests`,
      children: <SRList />,
    },
    {
      key: "2",
      label: `Create`,
      children: <CreateServiceRequest />,
    },
    // {
    //   key: "3",
    //   label: `Addresses`,
    //   children: <UserAddresses />,
    // },
    // {
    //   key: "4",
    //   label: `Profile`,
    //   children: <Profile />,
    // },
  ];

  return (
    <Fragment>
      <Col span={24}>
        <div style={{ textAlign: "end", marginRight: "15px" }}>
          <a
            onClick={() => {
              localStorage.removeItem("token");
              navigate("/");
            }}
            style={{ color: "#ae9572", fontWeight: "bold" }}
          >
            Logout
          </a>
        </div>
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} centered={true} />
      </Col>
    </Fragment>
  );
};

export default Dashboard;
