import { Fragment, useState, useRef, Context } from "react";
import { Col, Input, Button, Card, Table, Space, notification, Select, Checkbox } from "antd";
import { chunkSubstr } from "../utils/methods";

const Devices = (props) => {
  const [eid, setEid] = useState("");
  const [problem, setProblem] = useState(undefined);
  const [desc, setDesc] = useState("");
  const [ticketNo, setTicketId] = useState("");
  const [serviceTestingRequired, setServiceTestingRequired] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const badDescs = ["not working", "offline"];

  function onEidChange(event) {
    event.target.value = chunkSubstr(event.target.value.replaceAll(":", "").replaceAll("-", ""), 2).join(":");
    setEid(event.target.value);
  }

  function onDescChange(event) {
    setDesc(event.target.value);
  }
  function onTicketIdChange(event) {
    setTicketId(event.target.value);
  }

  function onServiceTestingRequiredChange(event) {
    setServiceTestingRequired(event.target.checked);
  }

  function onProblemSelect(value) {
    setProblem(value);
  }

  function onDelete(eid) {
    let newDevices = devices.filter((device) => device.eid !== eid);
    setDevices(newDevices);
    onDeleteMain(eid);
  }

  function onDeviceAdd() {
    let regEx = new RegExp("[A-Za-z0-9]{2}:[A-Za-z0-9]{2}:[A-Za-z0-9]{2}:[A-Za-z0-9]{2}:[A-Za-z0-9]{2}:[A-Za-z0-9]{2}:[A-Za-z0-9]{2}:[A-Za-z0-9]{2}");
    let regEx2 = new RegExp("[A-Za-z0-9]{2}-[A-Za-z0-9]{2}-[A-Za-z0-9]{2}-[A-Za-z0-9]{2}-[A-Za-z0-9]{2}-[A-Za-z0-9]{2}-[A-Za-z0-9]{2}-[A-Za-z0-9]{2}");
    if (!regEx.test(eid) && !regEx2.test(eid)) {
      api.error({
        message: `Service Request`,
        description: "Please enter valid eID",
      });
      return;
    }
    let existing = devices.filter((device) => {
      return device.eid == eid;
    });
    if (existing.length > 0) {
      api.error({
        message: "Service Request",
        description: "Device is already added. Please add new Device",
      });
      return;
    }
    if (!problem || problem.trim() == "") {
      api.error({
        message: "Service Request",
        description: "Please select the problem you are facing with the device",
      });
      return;
    }
    if (!ticketNo || ticketNo.trim() == "") {
      api.error({
        message: "Service Request",
        description: "Zoho Ticket ID can not be empty. If you do not have ticket please write down the reason",
      });
      return;
    }

    let tempTicketNo = ticketNo.replace(/ /g, "");
    if (tempTicketNo == "" || tempTicketNo.length < 4) {
      api.error({
        message: "Service Request",
        description: "Zoho Ticket ID can not be Empty or Invalid. If you do not have ticket please write down the reason",
      });
      return;
    }

    if (problem.trim().toUpperCase() == "OTHERS" && desc.trim() == "") {
      api.error({
        message: "Service Request",
        description: "Please write some description for your problem as you selected 'Others'.",
      });
      return;
    }
    if (desc != undefined && badDescs.includes(desc.trim().toLowerCase())) {
      api.error({
        message: "Service Request",
        description: "Please explain the problem you are facing with the device in detail",
      });
      return;
    }
    setDevices([...devices, { eid, problem, desc, key: eid, ticketNo, serviceTestingRequired }]);
    setEid("");
    setProblem("");
    setDesc("");
    setTicketId("");
    setServiceTestingRequired(false);
    onDeviceAddMain({ eid, problem, desc, key: eid, ticketNo, serviceTestingRequired });
  }

  const columns = [
    {
      title: "eID",
      dataIndex: "eid",
      key: "eid",
    },
    {
      title: "Problem",
      dataIndex: "problem",
      key: "problem",
    },
    {
      title: "Description",
      dataIndex: "desc",
      key: "desc",
    },
    {
      title: "Ticket No",
      dataIndex: "ticketNo",
      key: "ticketNo",
    },
    {
      title: "Service Testing",
      dataIndex: "serviceTestingRequired",
      key: "serviceTestingRequired",
      render: (_, { serviceTestingRequired }) => <Space size="middle">{serviceTestingRequired ? "YES" : "NO"}</Space>,
    },
    {
      title: "Action",
      key: "action",

      render: (_, { eid }) => (
        <Space size="middle">
          <a
            onClick={() => {
              onDelete(eid);
            }}
            style={{ color: "red" }}
          >
            Delete
          </a>
        </Space>
      ),
    },
  ];

  const [devices, setDevices] = useState([]);

  const { onDeviceAddMain, onDeleteMain } = props;

  const problems = [
    "Device offline",
    "Node Reset",
    "Replaced HUB-Reset",
    "Offline device-No Led indication",
    "Sensor not working",
    "Led continuously blinking- the device is not a repeater",
    "Online device-No power output",
    "LED flickering",
    "No power in the load",
    "LED-Double blinking",
    "Physical Hardware damaged",
    "Device component missing",
    "Dead on arrival",
    "HUB offline-no LED",
    "HUB offline-LED Blinking",
    "Fast Battery draining",
    "Buzzer not working",
    "IR Remote not training",
    "IR blaster blinking continuously (Back LED and front)",
    "Others",
  ].map((value) => {
    return { value: value.toUpperCase(), label: value.toUpperCase() };
  });

  return (
    <Card
      className="glass"
      title="Devices"
      style={{
        width: "100%",
        marginTop: 20,
      }}
    >
      {contextHolder}
      <Col>
        <Input value={eid} onChange={onEidChange} placeholder="Enter Device eID" style={{ marginBottom: "10px" }} />
        <Select
          showSearch={true}
          style={{
            width: "100%",
            marginTop: "10px",
            marginBottom: "10px",
          }}
          placeholder="Select Problem"
          options={problems}
          value={problem}
          onSelect={onProblemSelect}
          filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
        />
        <Input
          value={desc}
          onChange={onDescChange}
          placeholder="Describe your problem (Optional)"
          style={{ marginBottom: "10px" }}
          onKeyDown={(event) => {}}
          styles={{
            marginTop: "10px",
            marginBottom: "10px",
          }}
        />
        <Input
          value={ticketNo}
          onChange={onTicketIdChange}
          placeholder="Zoho Ticket ID"
          style={{ marginBottom: "10px" }}
          onKeyDown={(event) => {
            if (event.key == "Enter") {
              onDeviceAdd();
            }
          }}
          styles={{
            marginTop: "10px",
            marginBottom: "10px",
          }}
        />
        <Checkbox checked={serviceTestingRequired} onChange={onServiceTestingRequiredChange} style={{ marginBottom: "10px" }}>
          Service Testing Required
        </Checkbox>
        <Button type="primary" style={{ backgroundColor: "#ae9572", color: "black", width: "100%" }} onClick={onDeviceAdd}>
          Add Device
        </Button>
      </Col>
      <h4>Added Devices :</h4>
      <Table style={{ marginTop: 20, overflow: "scroll" }} dataSource={devices} columns={columns} />
    </Card>
  );
};

export default Devices;
