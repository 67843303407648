import { Button, Checkbox, Col, Card, Form, Input, Row, Collapse, Select, notification, Modal, Table, Space, theme } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined, SettingOutlined, ArrowLeftOutlined, UserOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { Fragment, useEffect, useState } from "react";
import * as animationData from "../serviceanim.json";
import Lottie from "react-lottie";
import { validateEmail } from "../utils/methods";
import axios from "axios";
import { API_ENDPOINT } from "../api/endPoint";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
  const { useToken } = theme;
  const { token } = useToken();
  const [api, contextHolder] = notification.useNotification();
  const [isCreating, setIsCreating] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values) => {
    let { email, password, passwordRepeat, name, companyName } = values;
    if (password != passwordRepeat) {
      api.error({
        message: "eGlu Service Sign Up",
        description: "Passwords do not match.",
      });
      return;
    }
    if (!validateEmail(email)) {
      api.error({
        message: "eGlu Service Sign Up",
        description: "Email ID is not valid.",
      });
      return;
    }
    try {
      setIsCreating(true);
      let response = await axios.post(API_ENDPOINT + "/users/register", { email, password, name, companyName: name });
      api.success({
        message: "eGlu Service Sign Up",
        description: "Account is successfully created",
      });
      setIsCreating(false);
      navigate("/login");
    } catch (err) {
      api.error({
        message: "eGlu Service Sign Up",
        description: err.response?.data?.message ?? "Failed to create Account. Please try again",
      });
      setIsCreating(false);
    }
  };

  const onFinishFailed = () => {};

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/dashboard");
    }
  }, []);

  return (
    <Row type="flex" justify="center" align="middle" style={{ minHeight: "100vh" }}>
      {contextHolder}
      <Col style={{ textAlign: "center" }}>
        <Lottie options={defaultOptions} height={100} width={100} />
        <h2 style={{ color: token.colorText }}>eGlu Service Sign Up</h2>
        <div style={{ textAlign: "center", padding: 20 }} className="glass">
          <Form name="basic" style={{ maxWidth: 450 }} initialValues={{ remember: true }} onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off">
            <Form.Item label="Name" name="name" rules={[{ required: true, message: "Please input your Name!" }]}>
              <Input />
            </Form.Item>
            {/* <Form.Item label="Company Name" name="companyName" rules={[{ required: true, message: "Please input your Company Name!" }]}>
              <Input />
            </Form.Item> */}
            <Form.Item label="Email ID" name="email" rules={[{ required: true, message: "Please input your Email!" }]}>
              <Input />
            </Form.Item>

            <Form.Item label="Password" name="password" rules={[{ required: true, message: "Please input your Password!" }]}>
              <Input.Password />
            </Form.Item>

            <Form.Item label="Repeat Password" name="passwordRepeat" rules={[{ required: true, message: "Please input your Password one more time!" }]}>
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isCreating}>
                Sign Up
              </Button>
            </Form.Item>
          </Form>
        </div>
        <br />
        <br />
        <h4 style={{ color: token.colorText }}>
          <a href="/login" style={{ color: token.colorText, textDecoration: "underline" }}>
            Back to Login
          </a>
        </h4>
      </Col>
    </Row>
  );
};

export default SignUp;
