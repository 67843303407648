import { Button, Checkbox, Col, Form, Input, Row, Collapse, Select, Alert, Space } from "antd";
import { Content } from "antd/es/layout/layout";
import { useState } from "react";
import { isValidGSTIN } from "../utils/constants";
const { Panel } = Collapse;

const onFinish = (values) => {
  console.log("Success:", values);
};
const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

const states = new Array(
  "Andaman & Nicobar",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chandigarh",
  "Chhattisgarh",
  "Dadra & Nagar Haveli",
  "Daman & Diu",
  "Delhi",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu & Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Lakshadweep",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Orissa",
  "Pondicherry",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Tripura",
  "Uttar Pradesh",
  "Uttaranchal",
  "West Bengal"
).map((state) => {
  return { value: state, label: state };
});

const Address = (props) => {
  const { address, onAddressChange, type, isFromUserProfile, disabled } = props;

  function onNameChange(event) {
    address.name = event.target.value;
    onAddressChange(address);
  }
  function onAttentionChange(event) {
    address.attention = event.target.value;
    onAddressChange(address);
  }

  function onEmailChange(event) {
    address.email = event.target.value;
    onAddressChange(address);
  }

  function onPhoneChange(event) {
    address.phone = event.target.value;
    onAddressChange(address);
  }

  function onAddressLine1Change(event) {
    address.addressLine1 = event.target.value;
    onAddressChange(address);
  }
  function onAddressLine2Change(event) {
    address.addressLine2 = event.target.value;
    onAddressChange(address);
  }

  function onAddressLine3Change(event) {
    address.addressLine3 = event.target.value;
    onAddressChange(address);
  }

  function onCityChange(event) {
    address.city = event.target.value;
    onAddressChange(address);
  }

  function onZipChange(event) {
    address.zip = event.target.value;
    onAddressChange(address);
  }

  function onStateChange(value, _) {
    address.state = value;
    onAddressChange(address);
  }

  function onCountryChange(event) {
    address.country = event.target.value;
    onAddressChange(address);
  }

  function onGSTINChange(event) {
    address.GSTIN = event.target.value;
    onAddressChange(address);
  }

  let formStyle = {};
  if (isFromUserProfile) {
    formStyle = { width: "100%" };
  } else {
    formStyle = { maxWidth: 600 };
  }

  return (
    <Col>
      {/* {type == "billing" && <Alert message="If you want to claim GST, please add your company details below. Billing Address CAN NOT be edited later." type="warning" showIcon closable />} */}
      <br></br>
      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={formStyle}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={{
          ...address,
        }}
        disabled={disabled}
      >
        <Form.Item
          label="Name*"
          rules={[
            {
              required: true,
              message: "Please input Name!",
            },
          ]}
          help={type == "billing" && address.GSTIN != undefined && address.GSTIN != "" && `Please make sure that Name matches with the Legal/Trade Name registered in the GST Portal`}
        >
          <Input onChange={onNameChange} value={address.name} placeholder="Name" />
        </Form.Item>
        <Form.Item
          label="Contact Person*"
          rules={[
            {
              required: true,
              message: "Please input Attention!",
            },
          ]}
        >
          <Input onChange={onAttentionChange} value={address.attention} placeholder="Attention" />
        </Form.Item>
        <Form.Item
          label="Email*"
          rules={[
            {
              required: true,
              message: "Please input Email!",
            },
          ]}
        >
          <Input onChange={onEmailChange} type="email" value={address.email} placeholder="Email" />
        </Form.Item>

        <Form.Item
          label="Mobile*"
          rules={[
            {
              required: true,
              message: "Please input 10 Digit Mobile No!",
            },
          ]}
        >
          <Input onChange={onPhoneChange} type="number" maxLength={10} value={address.phone} placeholder="10 Digit Mobile No" />
        </Form.Item>

        <Form.Item
          label="Address Line 1*"
          rules={[
            {
              required: true,
              message: "Please input Address Line 1!",
            },
          ]}
        >
          <Input onChange={onAddressLine1Change} value={address.addressLine1} placeholder="Address Line 1 (Max 30 Characters)" maxLength={30} />
        </Form.Item>
        <Form.Item
          label="Address Line 2*"
          rules={[
            {
              required: true,
              message: "Please input Address Line 2!",
            },
          ]}
        >
          <Input onChange={onAddressLine2Change} value={address.addressLine2} placeholder="Address Line 2 (Max 30 Characters)" maxLength={30} />
        </Form.Item>

        <Form.Item label="Address Line 3">
          <Input onChange={onAddressLine3Change} value={address.addressLine3} placeholder="Address Line 3 (Optional)(Max 30 Characters)" maxLength={30} />
        </Form.Item>
        <Form.Item
          label="City*"
          rules={[
            {
              required: true,
              message: "Please input City!",
            },
          ]}
        >
          <Input onChange={onCityChange} value={address.city} placeholder="City" />
        </Form.Item>
        <Form.Item
          label="Zip*"
          rules={[
            {
              required: true,
              message: "Please input Zip!",
            },
          ]}
        >
          <Input onChange={onZipChange} type="number" value={address.zip} placeholder="Zip Code" />
        </Form.Item>
        <Form.Item
          label="State*"
          rules={[
            {
              required: true,
              message: "Please input State!",
            },
          ]}
        >
          <Select
            showSearch
            style={{
              width: "100%",
            }}
            options={states}
            onSelect={onStateChange}
            value={address.state}
            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
          />
        </Form.Item>
        <Form.Item
          label="Country*"
          rules={[
            {
              required: true,
              message: "Please input Country!",
            },
          ]}
        >
          <Input onChange={onCountryChange} value={address.country} defaultValue={`India`} placeholder="Country" />
        </Form.Item>
        <Form.Item label="GSTIN" validateStatus={address.GSTIN == undefined ? `success` : isValidGSTIN(address.GSTIN) ? `success` : `error`}>
          <Input onChange={onGSTINChange} value={address.GSTIN} placeholder="GSTIN (Optional)" />
        </Form.Item>
      </Form>
    </Col>
  );
};

export default Address;
